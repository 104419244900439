export default {
    data() {
        return {
            selectedItems: [],
        }
    },
    methods: {
        selectItem(item) {
            let ind = this.selectedItems.findIndex(ele => (ele.bizId || ele.id) === (item.bizId || item.id));
            if (ind >= 0) {
                this.selectedItems.splice(ind, 1);
            } else {
                this.selectedItems.push(item);
            }
        },
        isSelected(item) {
            return this.selectedItems.findIndex(ele => (ele.bizId || ele.id) === (item.bizId || item.id)) >= 0;
        },
        handleCheckAll() {
            if (this.selectedItems.length) {
                // 注意 每次换页时，需要清空：
                this.selectedItems.splice(0);
            } else {
                this.selectedItems.push(...this.list);
            }
        },
        refreshSelects() {
            this.selectedItems = this.list.filter(
                ele => this.selectedItems.findIndex(e => (ele.bizId || ele.id) === (e.bizId || e.id)) >= 0
            );
        },
    }
}