<template>
  <div style="display: inline-block">
    <Button v-if="onshelfFn" @click="__onShelf" :disabled="!onAble"
      >上架</Button
    >
    <template v-if="offshelfFn">
      <Button v-if="needOffReason" @click="beforeOffShelf" :disabled="!offAble"
        >下架</Button
      >
      <Button v-else @click="__offShelf" :disabled="!offAble">下架</Button>
    </template>

    <Modal title="请输入下架原因" v-model="offShelfModal">
      <Input v-model="offShelfText" :maxlength="50" />
      <span>{{ offShelfText.length }}/50</span>
      <template slot="footer">
        <Button type="text" @click="offShelfModal = false">取消</Button>
        <Button
          :disabled="!offShelfText.trim()"
          type="primary"
          @click="__offShelf"
          >确定</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array,
    },
    onshelfFn: {
      type: [Function, Boolean],
      default: function () {
        throw new Error(
          "使用 ListFrame 的【批量上下架】功能你必须提供一个类型为Object的 shelfOperateAble 的 prop， 且包含类型为 list:[] => Promise 的 onshelfFn 属性"
        );
      },
    },
    offshelfFn: {
      type: [Function, Boolean],
      default: function () {
        throw new Error(
          "使用 ListFrame 的【批量上下架】功能你必须提供一个类型为Object的 shelfOperateAble 的 prop， 且包含类型为 (list:[], offShelfReason:string) => Promise 的 offshelfFn 属性"
        );
      },
    },
    statusName: {
      default: "status",
    },
    upValue: {
      default: "3",
    },
    needOffReason: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    onAble() {
      return (
        this.selectedItems.length &&
        this.selectedItems.every((ele) => ele[this.statusName] != this.upValue)
      );
    },
    offAble() {
      return (
        this.selectedItems.length &&
        this.selectedItems.every((ele) => ele[this.statusName] == this.upValue)
      );
    },
  },
  data() {
    return {
      offShelfModal: false,
      offShelfText: "",
    };
  },
  methods: {
    beforeOffShelf() {
      this.offShelfModal = true;
      this.offShelfText = "";
    },
    __onShelf() {
      this.onshelfFn(this.selectedItems)
        .then(() => {
          this.$emit("shelfStatusChange");
        })
        .catch((err) => {
          this.$Message.warning(err.message||err);
        });
    },
    __offShelf() {
      this.offshelfFn(this.selectedItems, this.offShelfText)
        .then(() => {
          this.$emit("shelfStatusChange");
        })
        .catch((err) => {
          this.$Message.warning(err.message||err);
        })
        .finally(() => {
          this.offShelfModal = false;
        });
    },
  },
};
</script>

<style>
</style>