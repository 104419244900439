<template>
  <div style="display:inline-block">
    <Button
      type="error"
      :disabled="!selectedItems.length"
      style="width:6em"
      @click="delBatchModal = true"
    >删除</Button>
    <Modal title="请输入删除原因备注" v-model="delBatchModal">
      <Input v-model="delBatchText" :maxlength="50" />
      <span>{{delBatchText.length}}/50</span>
      <template slot="footer">
        <Button type="text" @click="delBatchModal=false">取消</Button>
        <Button :disabled="!(delBatchText.trim())" type="primary" @click="deleteBatch">确定</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array
    },
    delFn: {
      type: Function,
      default: function() {
        throw new Error(
          '使用 ListFrame 的【批量删除】功能你必须提供一个 delFn 的 prop ,类型: (list:[], delReason:string) => Promise '
        );
      }
    }
  },
  data() {
    return {
      delBatchModal: false,
      delBatchText: ''
    };
  },
  methods: {
    deleteBatch() {
      this.delFn(this.selectedItems, this.delBatchText).then(() => {
        this.delBatchText = '';
        this.delBatchModal = false;
        this.$Message.success('删除成功');
        this.$emit('delSuccess');
      });
    }
  }
};
</script>

<style>
</style>