<template>
  <div class="p-l-md p-r-md">
    <div class="m-b-xs flex conditions-wrapper">
      <Input
        v-if="
          viewConfig.showLvl &&
          (viewConfig.showLvl.show === undefined || viewConfig.showLvl.show)
        "
        v-model="contentLevelStr"
        class="m-r-md cond-level"
        style="width: 15em"
        search
        enter-button="查等级"
        placeholder="如：1或1-10"
        @on-search="refresh"
      />
      <Input
        v-if="
          viewConfig.showTitle &&
          (viewConfig.showTitle.show === undefined || viewConfig.showTitle.show)
        "
        v-model="contentTitleStr"
        class="m-r-md cond-title"
        style="width: 15em"
        search
        enter-button="查标题"
        placeholder="如：title"
        @on-search="refresh"
      />
      <Input
        v-if="
          viewConfig.showContent &&
          (viewConfig.showContent.show === undefined ||
            viewConfig.showContent.show)
        "
        v-model="singleMdContent"
        class="m-r-md cond-content"
        style="width: 15em"
        search
        enter-button="查内容"
        placeholder="如：content"
        @on-search="refresh"
      />
      <slot name="conditions" :requestFn="refresh" :list="list"></slot>
      <div
        class="cond-shelf"
        v-if="viewConfig.showShelStatus &&
          (viewConfig.showShelStatus.show === undefined ||
            viewConfig.showShelStatus.show)
        "
      >
        <SelfSelect class="m-r-md" style="width: 6em" v-model="shelfStatus" @input="refresh" />
      </div>

      <div
        v-if="viewConfig.showReorder &&
          (viewConfig.showReorder.show === undefined ||viewConfig.showReorder.show)"
        class="cond-order"
      >
        <OrderSelect v-model="orderFlag" class="m-r-md" @input="refresh" />
      </div>
      <div v-if="viewConfig.showReset" class="cond-reset">
        <Button
          class="m-r-md"
          style="width: 6em"
          type="primary"
          @click="reinitConditions"
          title="重置搜索条件"
        >重置</Button>
      </div>
    </div>

    <template v-if="list && list.length">
      <template>
        <!-- 这里是针对已选items，批量操作的界面 -->
        <Checkbox
          v-if="selectAble"
          :indeterminate="
            selectedItems.length > 0 && selectedItems.length < list.length
          "
          :value="selectedItems.length === list.length"
          @click.prevent.native="handleCheckAll"
        >全选</Checkbox>
        <SelfOperate
          class="m-l-sm"
          v-if="!!viewConfig.shelfOperateAble"
          :selectedItems="selectedItems"
          :onshelfFn="viewConfig.shelfOperateAble.onshelfFn"
          :offshelfFn="viewConfig.shelfOperateAble.offshelfFn"
          :statusName="viewConfig.shelfOperateAble.statusName || 'status'"
          :upValue="viewConfig.shelfOperateAble.upValue || '3'"
          :needOffReason="viewConfig.shelfOperateAble.needOffReason"
          @shelfStatusChange="onPageChange"
        />
        <DelBatch
          class="m-l-sm"
          v-if="!!viewConfig.itemsDelAble"
          :selectedItems="selectedItems"
          :delFn="viewConfig.itemsDelAble.delFn"
          @delSuccess="onPageChange"
        />
        <slot name="batch-opterate" :items="selectedItems" :requestFn="refresh"></slot>
      </template>
      <div v-if="selectAble" style="height: 1em"></div>
      <slot
        name="list"
        :list="list"
        :select="selectItem"
        :selectedItems="selectedItems"
        :pageNo="pageNo"
        :pageSize="pageSize"
        :requestFn="refresh"
      >
        <div
          v-for="(item, index) in list"
          :key="item[(customedParams && customedParams.itemKey) || 'id']"
          class="flex m-b-md"
        >
          <slot
            name="item-no"
            :item="item"
            :index="index"
            :list="list"
            :select="selectItem"
            :selectedItems="selectedItems"
          >
            <Checkbox
              v-if="selectAble"
              style="margin-top: 3px"
              :value="isSelected(item)"
              @on-change="selectItem(item)"
            ></Checkbox>
            <!--             -->
            <p
              v-else-if="viewConfig.showNo"
              class="p-r-sm"
              style="padding-top: 3px"
            >{{ (pageNo - 1) * pageSize + index + 1 }}.</p>
          </slot>
          <div class="flex1">
            <slot
              :item="item"
              :index="index"
              :list="list"
              :select="selectItem"
              :requestFn="refresh"
            ></slot>
          </div>
        </div>
      </slot>
      <Page
        v-if="
          viewConfig.showPager &&
          (viewConfig.showPager.show === undefined || viewConfig.showPager.show)
        "
        :total="totalCount"
        show-total
        show-sizer
        :current="pageNo"
        :page-size="pageSize"
        :page-size-opts="[pageSize]"
        @on-change="initPage"
      />
    </template>
    <div v-else class="page-tip">{{ emptyMsg }}</div>
  </div>
</template>

<script>
import selectAbleMixin from "./selectableMixin";
import SelfSelect from "./SelfSelect";
import OrderSelect from "./OrderSelect";
import SelfOperate from "./SelfOperate";
import DelBatch from "./DelBatch";
const Error_getPage = new Error(
  "ListFrame 的prop：getPage 必须是 params=>Promise<{rows:Array,totalCount:Number}> 函数类型"
);
const defaultViewConf = {
  // viewConf
  showLvl: false, // string, boolean, obj {show?:true,name?:'contentLevelStr',initial?:''}
  showTitle: false, // string, boolean,obj {show?:true,name?:'contentTitleStr',initial?:''}
  showContent: false, // string, boolean,obj {show?:true,name?'singleMdContent',initial?:''}
  showReorder: true, // string, boolean, obj {show?:true,name?:'orderFlag',initial?:'0'} // string 表示name，即请求时的参数名
  showShelStatus: false, // string, boolean, obj {show?:true,name?:'status',initial?:''}
  showPager: true, // boolean or {show?:true, pageNo?:string or {name?:'pageNo',initial?:1}, pageSize?:string or {name?:'pageSize',initial?:10}}
  showNo: true,

  showReset: false, // false or true(any) or obj {onReset?:f}
  shelfOperateAble: false, // false or obj {offshelfFn:f|false,onshelfFn:f|false,statusName?:'status',upValue?:'3',needOffReason:true}
  itemsDelAble: false, // false or obj {delFn:f}
  selectAble: false // boolean
};
/**
 * @param {string|boolean|{name:string}} config
 * @param {string} defaultName
 */
const getParamName = (config, defaultName) => {
  if (typeof config === "boolean" || config instanceof Boolean) {
    return defaultName;
  }
  if (typeof config === "string" || config instanceof String) {
    return config || defaultName;
  }
  if (!config) {
    return defaultName;
  }
  return config.name ?? defaultName;
};
/**
 * @param {string|boolean|{initial}} config
 * @param {string} defaultName
 */
const getInitialValue = (config, defaultValue) => {
  if (typeof config === "object" && config && config.initial !== undefined) {
    return config.initial;
  }
  return defaultValue;
};
export default {
  components: { SelfSelect, OrderSelect, SelfOperate, DelBatch },
  props: {
    viewConf: {
      // 界面视图所展示的组件的配置，详见上面的 defaultViewConf
      type: Object
    },
    customedParams: {
      // 通常用于自定义的筛选条件字段
      type: Object,
      default() {
        return {
          itemKey: "id"
        };
      }
    },
    autoRefresh: {
      type: Boolean | String, //string as type 默认'anytime'
      // {  type?:  'anytime'|'when-no-select',
      //    time?:  30 }
      default: false
    },
    immediateInit: {
      type: Boolean,
      default: true
    },
    keepStatus: {
      type: Boolean,
      default: false
    },
    getPageFn: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      list: [],
      totalCount: 0,
      emptyMsg: "",

      pageNo: 1,
      pageSize: 10,
      contentLevelStr: "",
      contentTitleStr: "",
      singleMdContent: "",
      shelfStatus: "",
      orderFlag: "0"
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    laun() {
      return this.$store.getters.getLaun;
    },
    viewConfig() {
      return Object.assign({}, defaultViewConf, this.viewConf);
    },
    selectAble() {
      return (
        this.viewConfig.selectAble ||
        !!this.viewConfig.shelfOperateAble ||
        !!this.viewConfig.itemsDelAble
      );
    }
  },
  mixins: [selectAbleMixin],
  methods: {
    __initParams(urlQuery) {
      let initial = Object.assign({}, urlQuery);
      // 初始值：
      this.pageSize =
        +initial[
          getParamName(this.viewConfig.showPager.pageSize, "pageSize")
        ] || +getInitialValue(this.viewConfig.showPager.pageSize, 10);
      this.pageNo =
        +initial[getParamName(this.viewConfig.showPager.pageNo, "pageNo")] ||
        +getInitialValue(this.viewConfig.showPager.pageNo, 1);
      this.orderFlag =
        initial[getParamName(this.viewConfig.showReorder, "orderFlag")] ||
        getInitialValue(this.viewConfig.showReorder, "0");
      this.contentLevelStr =
        initial[getParamName(this.viewConfig.showLvl, "contentLevelStr")] ||
        getInitialValue(this.viewConfig.showLvl, "");
      this.contentTitleStr =
        initial[getParamName(this.viewConfig.showTitle, "contentTitleStr")] ||
        getInitialValue(this.viewConfig.showTitle, "");
      this.singleMdContent =
        initial[getParamName(this.viewConfig.showContent, "singleMdContent")] ||
        getInitialValue(this.viewConfig.showContent, "");
      this.shelfStatus =
        initial[getParamName(this.viewConfig.showShelStatus, "status")] ||
        getInitialValue(this.viewConfig.showShelStatus, "3");
    },
    refresh() {
      return this.initPage();
    },
    async initPage(pageNo) {
      // step1:封装参数
      pageNo = +pageNo || 1;
      let params = Object.assign({}, this.$route.query, this.customedParams, {
        taskName: this.roleCode,
        [getParamName(this.viewConfig.showPager.pageNo, "pageNo")]: pageNo,
        [getParamName(this.viewConfig.showPager.pageSize, "pageSize")]: this
          .pageSize
      });
      if (this.viewConfig.showLvl) {
        params[
          getParamName(this.viewConfig.showLvl, "contentLevelStr")
        ] = this.contentLevelStr;
      }
      if (this.viewConfig.showTitle) {
        params[
          getParamName(this.viewConfig.showTitle, "contentTitleStr")
        ] = this.contentTitleStr;
      }
      if (this.viewConfig.showContent) {
        params[
          getParamName(this.viewConfig.showContent, "singleMdContent")
        ] = this.singleMdContent;
      }
      if (this.viewConfig.showReorder) {
        params[
          getParamName(this.viewConfig.showReorder, "orderFlag")
        ] = this.orderFlag;
      }
      if (this.viewConfig.showShelStatus) {
        params[
          getParamName(this.viewConfig.showShelStatus, "status")
        ] = this.shelfStatus;
      }

      // step2:调用
      let promise = this.getPageFn(params);
      if (!(promise instanceof Promise)) {
        throw Error_getPage;
      }
      this.list = [];
      this.emptyMsg = "";
      this.$Spin.show();
      let { rows, totalCount } = await promise
        .catch(err => {
          this.emptyMsg = "后台请求失败，请联系管理人员！";
          return Promise.reject(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.$Spin.hide();
          }, 100);
        });
      if (
        rows === undefined ||
        !Array.isArray(rows) ||
        totalCount === undefined
      ) {
        throw Error_getPage;
      }
      this.emptyMsg = "没有符合条件的数据";
      this.list = rows;
      this.totalCount = totalCount;
      this.pageNo = pageNo;
      this.refreshSelects();
      // step3: 调用结束后：将最终的条件缓存到 url 中
      if (this.keepStatus) {
        params.taskName = undefined;
        params[
          getParamName(this.viewConfig.showPager.pageSize, "pageSize")
        ] = undefined;
        this.$router.replace({
          path: this.$route.path,
          query: params
        });
      }
      // over!
    },
    onPageChange() {
      this.selectedItems = [];
      this.initPage(this.pageNo);
    },
    reinitConditions() {
      if (
        this.viewConfig.showReset &&
        typeof this.viewConfig.showReset.onReset === "function"
      ) {
        this.viewConfig.showReset.onReset();
      }
      this.__initParams();
      this.initPage(this.pageNo);
    },
    __openTimedRefresh(tickTime = 1000) {
      if (this.initPage.timmer !== undefined) {
        // 这个清理很重要
        clearInterval(this.initPage.timmer);
        this.initPage.timmer = undefined;
      }

      let lastReqTime = Date.now();
      let oldFn = (this.initPage.oldFn || this.initPage).bind(this); // 1
      this.initPage = function(...params) {
        // console.log('use proxy');
        lastReqTime = Date.now();
        // 必须return，保持一致
        return oldFn(...params).then(() => {
          lastReqTime = Date.now();
        });
      };
      this.initPage.oldFn = oldFn; // 1

      this.initPage.timmer = setInterval(() => {
        if (
          (this.autoRefresh === "when-no-select" ||
            this.autoRefresh.type === "when-no-select") &&
          this.selectedItems.length > 0
        ) {
          lastReqTime += tickTime;
          return;
        }
        if (Date.now() >= lastReqTime + (this.autoRefresh.time || 30) * 1000) {
          this.initPage(this.pageNo);
        }
      }, tickTime);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.initPage.timmer);
        this.initPage.timmer = undefined;
      });
    }
  },
  beforeMount() {
    // console.log('%cbefore mount', 'color:blue');
    this.__initParams(this.$route.query);
    if (this.immediateInit) {
      if (this.autoRefresh) {
        this.__openTimedRefresh();
      }
      this.initPage(this.pageNo);
    } else {
      this.emptyMsg =
        "刚进来不会自动加载列表数据，请通过上面的搜索或排序条件来加载数据";
      if (this.autoRefresh) {
        let inited = false;
        const _this = this;
        const olfFn = this.initPage.bind(this);
        this.initPage = function(...params) {
          if (!inited) {
            inited = true;
            _this.__openTimedRefresh();
          }
          return olfFn(...params);
        };
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
.ivu-input-group {
  height: 32px;
}
.search {
  display: inline-block;
}
.fl.m-r-md {
  margin-bottom: 12px;
}
.conditions-wrapper {
  flex-wrap: wrap;
  align-content: space-between;
}
.cond-level {
  order: -3;
}
.cond-title {
  order: -2;
}
.cond-content {
  order: -1;
}
.cond-shelf {
  order: 7;
}
.cond-order {
  order: 8;
}
.cond-reset {
  order: 9;
}
</style>