<template>
  <Select v-model="shelfStatus" placeholder="上架状态">
    <Option :value="'all'">全部</Option>
    <Option :value="'3'">已上架</Option>
    <Option :value="'4'">已下架</Option>
  </Select>
</template>

<script>
export default {
  props: { value: {} },
  computed: {
    shelfStatus: {
      get() {
        return this.value || '3';
      },
      set(v) {
        if (v === 'all') {
          this.$emit('input', '');
        } else {
          this.$emit('input', v);
        }
      }
    }
  }
};
</script>

<style>
</style>