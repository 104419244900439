<template>
  <Select style="width:8em;" :value="value" placeholder="排序方式" @on-change="onchange">
    <!-- //("0","时间倒序"),("1","时间顺序"),("2","等级倒序"),("3","等级顺序"); -->
    <Option :value="'0'">默认排序</Option>
    <Option :value="'1'">最早时间</Option>
    <Option :value="'3'">低级到高级</Option>
    <Option :value="'2'">高级到低级</Option>
  </Select>
</template>

<script>
export default {
  props: {
    value: {}
  },
  methods: {
    onchange(e) {
      this.$emit('input', e);      
    }
  }
};
</script>

<style>
</style>